module.exports = [{
      plugin: require('/var/lib/jenkins/workspace/bgm-devdocs-release/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/bgm-devdocs-release/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/bgm-devdocs-release/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":4000},
    },{
      plugin: require('/var/lib/jenkins/workspace/bgm-devdocs-release/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
